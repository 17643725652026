import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 2em auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Using this OSS loading animations
// https://github.com/nzbin/three-dots

const DotElastic = styled.div`
  &.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    -webkit-animation: dot-elastic 1s infinite linear;
    animation: dot-elastic 1s infinite linear;
  }

  &.dot-elastic::before,
  &.dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &.dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    -webkit-animation: dot-elastic-before 1s infinite linear;
    animation: dot-elastic-before 1s infinite linear;
  }

  &.dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    -webkit-animation: dot-elastic-after 1s infinite linear;
    animation: dot-elastic-after 1s infinite linear;
  }

  @-webkit-keyframes dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @-webkit-keyframes dot-elastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dot-elastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @-webkit-keyframes dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`

const Spinner = () => {
  return (
    <Wrapper>
      <DotElastic className="dot-elastic" />
    </Wrapper>
  )
}

export default Spinner

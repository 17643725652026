import React from 'react'
import Helmet from 'react-helmet'
import * as queryString from 'query-string'
import * as contentful from 'contentful'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import CardList from '../components/CardList'
import CardSearchResult from '../components/CardSearchResult'
import Spinner from '../components/Spinner'

const spaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
const contentfulDeliveryAccessToken =
  process.env.GATSBY_CONTENTFUL_DEVELIVERY_TOKEN
const contentfulClient = contentful.createClient({
  space: spaceId,
  environment: 'master',
  accessToken: contentfulDeliveryAccessToken,
})
const limitNumber = 18

const Button = styled.button`
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  padding: 1em;

  background: ${props => props.theme.colors.primary} !important;
  color: white !important;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 0.3em;
  &:hover {
    opacity: 0.8;
  }
`

class Contact extends React.Component {
  constructor() {
    super()
    this.state = {
      posts: [],
      postsTotalNumber: 0,
      hasNoResult: false,
      q: '',
      isLoading: false,
    }

    this.getMoreEntries = this.getMoreEntries.bind(this)
  }

  async getEntriesByKeyword() {
    this.setState({ isLoading: true })
    const { q } = queryString.parse(this.props.location.search)
    this.setState({ q })

    try {
      const res = await contentfulClient.getEntries({
        query: q,
        content_type: 'post',
        limit: limitNumber,
      })
      this.setState({
        posts: res.items,
        postsTotalNumber: res.total,
        hasNoResult: res.total === 0,
        isLoading: false,
      })
    } catch (error) {
      this.setState({ isLoading: false })
    }
  }

  async getMoreEntries(e) {
    e.preventDefault()
    this.setState({ isLoading: true })

    try {
      const res = await contentfulClient.getEntries({
        query: this.state.q,
        content_type: 'post',
        limit: limitNumber,
        skip: this.state.posts.length,
      })
      this.setState({
        posts: this.state.posts.concat(res.items),
        isLoading: false,
      })
    } catch (error) {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount() {
    this.getEntriesByKeyword()
  }

  render() {
    const pageTitle = `${this.state.q || ''} 検索 - ${config.siteTitle}`
    const postNode = {
      title: pageTitle,
    }
    const hasMorePosts = this.state.postsTotalNumber > this.state.posts.length

    return (
      <Layout>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath="search" customTitle />

        {this.state.posts.length === 0 && this.state.isLoading && <Spinner />}

        <Container>
          {this.state.hasNoResult && (
            <PageTitle>
              「{this.state.q}」の記事はまだありませんでした...
            </PageTitle>
          )}
          {this.state.posts.length > 0 && (
            <>
              <PageTitle>{this.state.q ? `${this.state.q} の検索結果` : 'おすすめの記事はこちらです'}</PageTitle>
              <CardList>
                {this.state.posts.map(({ fields: post }) => (
                  <CardSearchResult key={post.slug} {...post} />
                ))}
              </CardList>
              {this.state.isLoading && <Spinner />}
              {hasMorePosts && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <Button onClick={this.getMoreEntries}>LOAD MORE</Button>
                </div>
              )}
            </>
          )}
        </Container>
      </Layout>
    )
  }
}

export default Contact
